/**
 * Created by decipaliz on 12.03.2024
 */

GoalsView.prototype.createStartAnimation = function () {
    var scene = cleverapps.scenes.getRunningScene();
    var styles = cleverapps.styles.GoalsView.startAnimation;

    var baseScale = styles.messagesScale[cleverapps.resolution.mode] / this.scale;
    var availableWidth = styles.width;

    this.views.forEach(function (view) {
        view.upForStartAnimation();
        availableWidth -= view.width / styles.goals.scale[cleverapps.resolution.mode];
    });

    var margins = this.views.length + 1;
    var margin = availableWidth / margins * styles.goals.scale[cleverapps.resolution.mode];

    var startAnimation = this.startAnimation = new cleverapps.Layout(this.views, {
        direction: cleverapps.UI.HORIZONTAL,
        margin: margin
    });

    startAnimation.setPositionRound((-scene.width - this.startAnimation.width) / 2, scene.height / 2 + styles.goals.y * baseScale);
    startAnimation.setAnchorPoint(0.5, 0.5);
    startAnimation.setLocalZOrder(7);

    scene.addChild(startAnimation);

    var targetPosition = cc.p(scene.width + this.startAnimation.width / 2, 0);

    this.startAnimation.runAction(new cc.Sequence(
        new cc.DelayTime(0.1),
        new cc.PlaySound(bundles.game.urls.goals_fly_effect),
        new cc.MoveTo(0.9, scene.width / 2, this.startAnimation.y),
        new cc.DelayTime(1.0),
        new cc.MoveTo(0.5, targetPosition.x, this.startAnimation.y),
        new cc.CallFunc(function () {
            this.removeStartAnimation();

            targetPosition = this.getPosition();
            switch (cleverapps.resolution.mode) {
                case cleverapps.WideMode.VERTICAL: case cleverapps.WideMode.SQUARE:
                    this.setPositionRound(this.x, scene.height + this.height + 2);
                    break;
                case cleverapps.WideMode.HORIZONTAL:
                    this.setPositionRound(-this.width - 2, this.y);
                    break;
            }
            this.runAction(new cc.Sequence(
                new cc.DelayTime(cleverapps.styles.GoalsView.startAnimation.goals.delay),
                new cc.MoveTo(0.6, targetPosition).easing(cc.easeElasticOut(1.5))
            ));
        }.bind(this))
    ));
};